import AOS from 'aos';

AOS.init();

(function($) {
  $('.menu-btn').on('click', function(){
    const nav = $('nav ul')
    if(nav.hasClass('active')){
      nav.removeClass('active')
      $('.hamburger').removeClass('is-active')
    }else{
      nav.addClass('active')
      $('.hamburger').addClass('is-active')
    }
  })
 })(jQuery);